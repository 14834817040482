import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './core';
import { AuthLayoutComponent } from './core';
import { AuthGuard } from './auth.guard';

export const AppRoutes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      // PATHS FOR APPLICATION
      // {
      //   path: '',
      //   loadChildren: './dashboard/dashboard.module#DashboardModule'
      // },     
      {
        path: '',
        loadChildren: './dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'banners',
        loadChildren: './banners/banners/banners.module#BannersModule'
      }, {
        path: 'pedidos',
        loadChildren: './pedidos/pedidos.module#PedidosModule'
      }, {
        path: 'articulos',
        loadChildren: './articulos/articulos.module#ArticulosModule'
      },
      {
        path: 'promociones',
        loadChildren: './promociones/promociones.module#PromocionesModule'
      },
      {
        path: 'manifiesto',
        loadChildren: './manifiesto/manifiesto.module#ManifiestoModule'
      },
      {
        path: 'mining',
        loadChildren: './mining/mining.module#MiningModule'
      },
      // {
      //   path: 'vocimagenes',
      //   loadChildren: './vocimagenes/vocimagenes.module#VocimagenesModule'
      // },
      {
        path:'voc',
        loadChildren: './voc/voc.module#VocModule'
      },
      {
        path: 'afiliaciones',
        loadChildren: './afiliaciones/afiliaciones.module#AfiliacionesModule'
      },
      {
        path: 'reportes',
        loadChildren: './reportes/reportes.module#ReportesModule'
      },
      
      // PATHS FOR TEMPLATE
      // {
      //   path: 'rubros',
      //   loadChildren: './rubros/rubros.module#RubrosModule'
      // },
      // {
      //   path: 'organizaciones',
      //   loadChildren: './organizaciones/organizaciones.module#OrganizacionesModule'
      // },
      // {
      //   path: 'auditorias',
      //   loadChildren: './auditorias/auditorias.module#AuditoriasModule'
      // },
      // {
      //   path: 'email',
      //   loadChildren: './email/email.module#EmailModule'
      // },
      // {
      //   path: 'components',
      //   loadChildren: './components/components.module#ComponentsModule'
      // },
      // {
      //   path: 'cards',
      //   loadChildren: './cards/cards.module#CardsModule'
      // },
      // {
      //   path: 'forms',
      //   loadChildren: './form/form.module#FormModule'
      // },
      // {
      //   path: 'tables',
      //   loadChildren: './tables/tables.module#TablesModule'
      // },
      // {
      //   path: 'datatable',
      //   loadChildren: './datatable/datatable.module#DatatableModule'
      // },
      // {
      //   path: 'charts',
      //   loadChildren: './charts/charts.module#ChartsModule'
      // },
      // {
      //   path: 'maps',
      //   loadChildren: './maps/maps.module#MapsModule'
      // },
      // {
      //   path: 'pages',
      //   loadChildren: './pages/pages.module#PagesModule'
      // },
      // {
      //   path: 'taskboard',
      //   loadChildren: './taskboard/taskboard.module#TaskboardModule'
      // },
      // {
      //   path: 'calendar',
      //   loadChildren: './fullcalendar/fullcalendar.module#FullcalendarModule'
      // },
      // {
      //   path: 'media',
      //   loadChildren: './media/media.module#MediaModule'
      // },
      // {
      //   path: 'social',
      //   loadChildren: './social/social.module#SocialModule'
      // },
      // {
      //   path: 'docs',
      //   loadChildren: './docs/docs.module#DocsModule'
      // }
    ]
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'account',
        loadChildren: './account/account.module#AccountModule'
      },
      {
        path: 'error',
        loadChildren: './error/error.module#ErrorModule'
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'error/404'
  }
];
