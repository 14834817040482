import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input()
  heading: string;
  @Output()
  toggleSidenav = new EventEmitter<void>();

  public profileImage = '';
  public profileName = '';
  ngOnInit() {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    this.profileImage = user.imageUrl;
    this.profileName = user.username;
  }

  public SignOut() {
    localStorage.removeItem('currentUser')
    location.reload();
  }
}
