import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatSidenavModule } from '@angular/material';

import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { GoogleChartsModule } from "angular-google-charts";
import { NgxDropzoneModule } from "ngx-dropzone";

import {
  MenuComponent,
  HeaderComponent,
  SidebarComponent,
  FooterComponent,
  AdminLayoutComponent,
  AuthLayoutComponent,
  AccordionAnchorDirective,
  AccordionLinkDirective,
  AccordionDirective
} from './core';
import { AuthGuard } from './auth.guard';
// import { AfiliacionesComponent } from './afiliaciones/afiliaciones.component';
// import { VocComponent } from './voc/voc.component';
// import { VoclineasComponent } from './voc/voclineas/voclineas.component';
// import { VocimagenesComponent } from './vocimagenes/vocimagenes.component';
//import { MiningComponent } from './mining/mining.component';
//import { TopproductosComponent } from './mining/topproductos/topproductos.component';
// import { ManifiestoComponent } from './manifiesto/manifiesto.component';
// import { PromocionesComponent } from './promociones/promociones.component';
//import { ArticulosComponent } from './articulos/articulos.component';
// import { BannersComponent } from './banners/banners.component';
// import { PedidosComponent } from './pedidos/pedidos.component';
//import { OrganizacionesComponent } from './organizaciones/organizaciones.component';
//import { AuditoriasComponent } from './auditorias/auditorias.component';
// import { RubrosComponent } from './rubros/rubros.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    // AfiliacionesComponent,
    // VocComponent,
    // VoclineasComponent,
    //VocimagenesComponent,
    //MiningComponent,
    //TopproductosComponent,
    // ManifiestoComponent
    //ArticulosComponent,
    // PromocionesComponent,
    // PedidosComponent,
    // BannersComponent,
    //AuditoriasComponent
    //OrganizacionesComponent,
    // RubrosComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes),
    FormsModule,
    HttpClientModule,
    // HttpClient,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    LoadingBarRouterModule,
    NgbModule.forRoot(),
    MatSidenavModule,
    SweetAlert2Module.forRoot({
      buttonsStyling: false,
      customClass: 'modal-content',
      confirmButtonClass: 'btn btn-primary',
      cancelButtonClass: 'btn'
  }),
  SweetAlert2Module,
  ReactiveFormsModule,
  GoogleChartsModule,
  NgxDropzoneModule
  ],
  providers: [
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
