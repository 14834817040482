import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state?: string;
  name?: string;
  type: string;
  icon?: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

const MENUITEMS = [
  {
    state: '/',
    name: 'HOME',
    type: 'link',
    icon: 'ion-ios-home'
  },
  // {
  //   state: 'pedidos',
  //   name: 'Pedidos',
  //   type: 'sub',
  //   icon: 'ion-ios-box',
  //   children: [
  //     {
  //       state: 'surtir',
  //       name: 'A Surtir'
  //     },
  //     {
  //       state: 'pendientes',
  //       name: 'Pendientes'
  //     },
  //     {
  //       state: 'enviados',
  //       name: 'Enviados'
  //     },
  //     {
  //       state: 'historial',
  //       name: 'Historial'
  //     }
  //   ]
  // },
  // {
  //   state: 'banners',
  //   name: 'Banners',
  //   type: 'link',
  //   icon: 'ion-ios-analytics',
  // },
  // {
  //   state: 'promociones',
  //   name: 'Promociones',
  //   type: 'sub',
  //   icon: 'ion-ios-box',
  //   children: [
  //     {
  //       state: 'descontinuados',
  //       name: 'Descontinuados'
  //     }
  //   ]
  // }
  //   icon: 'ion-ios-list'
  // // }, 
  // {
  //   state: 'articulos',
  //   name: 'Artículos',
  //   type: 'sub',
  //   icon: 'ion-ios-cart',
  //   children: [
  //     {
  //       state: 'carga',
  //       name: 'cargar artículos'
  //     },
  //     {
  //       state: 'panel-articulos',
  //       name: 'Panel artículos'
  //     }
  //   ]
  // }
];


try {
  const profileUser = JSON.parse(localStorage.getItem('currentUser'));
  const menuUser = profileUser.menuItems;

  menuUser.forEach(element => {
    // console.log(element)
    // if (element.type === 'link') {
    MENUITEMS.push(element);
    // }
  });
} catch (e) {
  console.log(e)
}


@Injectable()
export class MenuService {

  constructor(
    private http: HttpClient
  ) { }

  // ngOnInit() {
  //   console.log(localStorage.getItem('currentUser'));
  // }

  getAll(): Menu[] {


    return MENUITEMS;
  }


  public getMenu(menuItems: string) {
    return this.http.get('/api/menu/' + menuItems);
  }
}
